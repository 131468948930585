import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ensureReady, After } from '@jaredpalmer/after';
import './client.css';
import routes from './routes';
import mixpanel from 'mixpanel-browser';

ensureReady(routes).then(data =>
  hydrate(
    <BrowserRouter>
      <After data={data} routes={routes} />
    </BrowserRouter>,
    document.getElementById('root')
  )
);

if (module.hot) {
  module.hot.accept();
}

mixpanel.init('b9962237fd4236867151b1055cbfe7d9', {debug: false});
mixpanel.track_links('#resume', 'Clicked Resume');
mixpanel.track_links('#linkedin', 'Clicked LinkedIn');
mixpanel.track_links('#competencies', 'Clicked competencies');
mixpanel.track_links('#github', 'Clicked GitHub');
mixpanel.track_links('#mail', 'Clicked Mail');
mixpanel.track_links('.repo-link', 'Clicked repo link');
