import React from 'react';

import { asyncComponent } from '@jaredpalmer/after';
//import Spinner from "react-spinkit";
//import "react-spinkit/css/base.css";
//import "react-spinkit/css/cube-grid.css";
import { Text } from "rebass";

const Placeholder = () => 
  <div style={{ marginLeft: "auto", marginRight: "auto"}}>
    <Text>Loading...</Text>
  </div>;

export default [
  {
    path: '/',
    exact: true,
    component: asyncComponent({
      loader: () => import('./Home'),
      Placeholder,
    }),
  },
  {
    path: '/fr',
    component: asyncComponent({
      loader: () => import('./Home'),
      Placeholder,
    }),
  },
  {
    path: '/en',
    component: asyncComponent({
      loader: () => import('./Home'),
      Placeholder,
    }),
  },
  /*{
    path: '/legal',
    component: asyncComponent({
      loader: () => import('./Legal'),
      Placeholder,
    }),
  },
  {
    path: '/legal/fr',
    component: asyncComponent({
      loader: () => import('./Legal'),
      Placeholder,
    }),
  },
  {
    path: '/legal/en',
    component: asyncComponent({
      loader: () => import('./Legal'),
      Placeholder,
    }),
  },*/
  /*{
    path: '/about',
    exact: true,
    component: asyncComponent({
      loader: () => import('./About'),
      Placeholder,
    }),
  },*/
];
